@use 'variables' as *;

/*! purgecss start ignore */
// Because react-transition-group generates and adds
// animation classes on the fly, this file (and any
// other class animations initiated by JS) needs to
// be ignored by PurgeCSS

// FOR `Transition`
.fadeIn {
  transition: opacity 0.5s;
  opacity: 0.5;

  &.entering {
    opacity: 0.5;
  }

  &.entered {
    opacity: 1;
  }
}

// ======= Crossfade helper classes =======
// To fade one element directly over another,
// the top element has to be placed over the
// base element using absolute positioning.

// Always have the default element in the
// `.crossfadeContainer`, to maintain a
// desirable base height. This height should
// work for both the base and top elements.

// Put this class on a `TransitionGroup` component.
.crossfadeContainer {
  position: relative;
}

// Only apply a `.crossfadeItem` class to the
// element that is stacking OVER the base
// element. The height of this element will be
// defined by the height of the container element,
// which is determined by the height of the base
// element.

// Put this class on the direct child of the
// `CSSTransition` with prop `classNames="fadeInOut"`.
.crossfadeItem {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
}

// FOR `CSSTransition`

.fadeInOut {
  &-enter {
    opacity: 0;
  }

  &-enter-active,
  &-exit {
    transition: all 0.2s;
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
  }
}

.slideUp {
  &-enter {
    transform: translateY(5px);
  }

  &-enter-active,
  &-exit {
    transition: all 0.2s;
    transform: translateY(0);
  }

  &-exit-active {
    transform: translateY(5px);
  }
}

.slideDown {
  &-enter {
    transform: translateY(-5px);
  }

  &-enter-active,
  &-exit {
    transition: all 0.2s;
    transform: translateY(0);
  }

  &-exit-active {
    transform: translateY(-5px);
  }
}

.slideLeft {
  &-enter {
    transform: translateX(50%);
    opacity: 0;
  }

  &-enter-active {
    transform: translateX(0);
    transition: all 0.3s ease-in-out;
    opacity: 1;
  }

  &-exit {
    transform: translateX(-100%);
    transition: all 0.3s ease-in-out;
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
  }
}

.dotForward {
  &-enter {
    transform: translateX(-19px);
    transition: all 0.3s ease-in-out;
  }

  &-enter-active {
    width: 50% !important;
    transition: width 0.3s ease-in-out;
  }
}

.dotBackward {
  &-enter {
    transform: translateX(19px);
  }

  &-enter-active {
    transform: translateX(0);
    width: 50% !important;
    transition: all 0.3s ease-in-out;
  }
}

.scaleHeight {
  &-enter {
    max-height: 0;
    overflow: hidden;
  }

  &-enter-active,
  &-exit {
    transition: all 0.2s;
    max-height: 600px;
  }

  &-exit-active {
    max-height: 0;
    overflow: hidden;
  }
}

// component-specific animations

.basketButtons {
  &-enter {
    opacity: 0;
    transform: translateY(50%);

    @media screen and (min-width: $bp1) {
      transform: translateX(50%);
    }
  }

  &-enter-active,
  &-exit {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.2s;

    @media screen and (min-width: $bp1) {
      transform: translateX(0);
    }
  }

  &-exit-active {
    opacity: 0;
    transform: translateY(50%);

    @media screen and (min-width: $bp1) {
      transform: translateX(50%);
    }
  }
}

.slideout {
  &-enter {
    opacity: 0;
    transform: translateY(50%);

    @media screen and (min-width: $bp3) {
      transform: translateX(50%);
    }
  }

  &-enter-active,
  &-exit {
    transition:
      opacity 0.2s ease-in-out,
      transform 0.2s ease;
    opacity: 1;
    transform: translateY(0);

    @media screen and (min-width: $bp3) {
      transform: translateX(0);
    }
  }

  &-exit-active {
    opacity: 0;
    transform: translateY(50%);

    @media screen and (min-width: $bp3) {
      transform: translateX(50%);
    }
  }
}

.animation-pulse {
  &-enter,
  &-exit {
    transition: transform 0.1s;
  }

  &-enter-active {
    transform: scale(1.05);
  }

  &-enter-done {
    transform: scale(1);
  }
}

/*! purgecss end ignore */
