@use 'variables' as *;

.slideout {
  &-background {
    background-color: $blueberry-100;
    background-color: rgba($blueberry-100, 0.75);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;

    z-index: map-get($zIndex, slideoutBackgroundAndSaveOverlay);

    span {
      position: absolute;
      top: $spacingLg;
      left: $spacingLg;

      display: inline-block;

      padding: $spacingSm;
      background-color: $charcoal-100;
      color: $white-100;

      border-radius: $radiusSm;
      font-weight: 600;
      visibility: hidden;

      opacity: 0;
      transition:
        visibility 0.2s,
        opacity 0.2s;
    }

    &:focus {
      span {
        visibility: visible;
        opacity: 1;
      }
    }

    &:active {
      span {
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  &-w-840 {
    left: 0;
    width: 100%;

    @media screen and (min-width: $bp3) {
      left: calc(100% - 840px);
      width: 840px;
    }
  }

  &-w-720 {
    left: 0;
    width: 100%;

    @media screen and (min-width: $bp3) {
      left: calc(100% - 720px);
      width: 720px;
    }
  }

  &-w-500 {
    left: 0;
    width: 100%;

    @media screen and (min-width: $bp3) {
      left: calc(100% - 500px);
      width: 500px;
    }
  }

  &-w-full {
    left: 0;
    width: 100%;
  }

  &-container {
    background-color: $white-100;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: map-get($zIndex, slideout);
    overflow-y: scroll;
    overflow-x: hidden;
    transform: translateZ(0);
  }
}
