@use 'variables' as *;

// container classes, meant to be used
// near the top of pages

.centeredContainer {
  margin: $spacingSm auto;
  padding-left: $spacingMd;
  padding-right: $spacingSm;

  width: 100%;
  max-width: $containerWidth;

  @media screen and (min-width: $bp1) {
    padding-right: $spacingMd;
  }

  @media screen and (min-width: $bp2) {
    margin: $spacingMd auto;
    padding-right: $spacingLg;
    padding-left: $spacingLg;
  }
}

$mobileNavHeight: 70px;
$desktopNavHeight: 80px;
$stickyHeaderHeight: 98px;

.dashboardHeader {
  background-color: $white-100;

  border-top: 0 solid transparent;
  border-bottom: $borderWidthMd solid $ice-100;

  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 0px auto $spacingSm;

  z-index: map-get($zIndex, one);

  transition: top 0.2s, border-top-width 0.2s, border-top-color 0.2s;
}

.stickyHeader {
  position: fixed;

  top: $mobileNavHeight;

  @media screen and (min-width: $bp1) {
    top: $desktopNavHeight;
  }

  & ~ .centeredContainer {
    padding-top: $mobileNavHeight + $stickyHeaderHeight;
  }

  @media screen and (min-width: $bp1) {
    & ~ .centeredContainer {
      padding-top: $desktopNavHeight + $stickyHeaderHeight;
    }
  }

  @supports (position: sticky) {
    position: sticky;
    & ~ .centeredContainer {
      padding-top: 0;
    }
  }

  [data-useragent*='Trident'] & {
    position: static;
    & ~ .centeredContainer {
      padding-top: 0;
    }
  }
}

.stickyHeaderBlock {
  @extend .stickyHeader;

  display: block;
  top: 0;
  border-top-color: currentColor;
  border-bottom: 0;
}
