$baseFontSize: 16;

$sansSerif: 'Helvetica Neue', Helmet, Freesans, sans-serif;
$serif: Rockwell, Georgia, Times, 'Times New Roman', serif;

$leMondeCourrier: 'LeMondeCourrier', $serif;
$karla: 'Karla', $sansSerif;

$dmSerif: 'DMSerif';
$dmSerifItalic: 'DMSerifItalic', $serif;

$aktivGrotesk: 'aktiv-grotesk', $sansSerif;

// For use in Admin only
$roboto: 'Roboto', $sansSerif;
