@use 'sass:math';
@use 'variables' as *;

$buttonTransition: math.div($transitionDuration, 1.5) $transitionTiming;

.btn {
  appearance: none;

  font-family: $karla;

  font-weight: 500;
  font-size: 1rem;
  line-height: 1;
  display: inline-block;
  padding: 0.875rem 1.5rem;
  text-align: center;
  border: $borderWidthSm solid transparent;
  border-radius: $radiusSm;

  transition: all 0.2s;

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

a.linkPrimary,
.btnPrimary,
.btnPrimaryDark {
  &:hover,
  &:focus {
    color: $white-100;
    border-color: $green-110;
    background-color: $green-110;
  }

  &,
  &[disabled] {
    color: $white-100;
    border-color: $green-100;
    background-color: $green-100;
    text-decoration: none;
  }

  &[disabled] {
    opacity: 0.5;
  }
}

a.linkSecondary,
.btnSecondary {
  &:hover,
  &:focus {
    border-color: $charcoal-80;
    color: $charcoal-90;
  }

  & {
    border-color: $charcoal-60;
    color: $charcoal-90;
    background-color: $white-100;
  }

  &[disabled] {
    border-color: $charcoal-70;
    background-color: $charcoal-50;
  }
}

.btnOutlinePrimary {
  &:hover,
  &:focus {
    border-color: $green-100;
    color: $charcoal-90;
  }

  & {
    border-color: $green-100;
    color: $charcoal-90;
    background-color: $white-100;
  }

  &[disabled] {
    border-color: $charcoal-70;
    background-color: $charcoal-50;
  }
}

.btnOutlineSecondary {
  &:hover,
  &:focus {
    border-color: $charcoal-80;
    color: $charcoal-90;
  }

  & {
    border-color: $charcoal-80;
    color: $charcoal-90;
    background-color: $white-100;
  }

  &[disabled] {
    border-color: $charcoal-70;
    background-color: $charcoal-50;
  }
}

.btnCaution {
  &:hover,
  &:focus {
    color: $white-100;
    border-color: $red-110;
    background-color: $red-110;
  }

  &,
  &[disabled] {
    color: $white-100;
    border-color: $red-100;
    background-color: $red-100;
  }
}

.btnGhost {
  &:hover,
  &:focus {
    background-color: $charcoal-50;
    background-color: rgba($color: #fff, $alpha: 0.1);
  }

  &,
  &[disabled] {
    color: $white-100;
    border-color: $white-100;
    background-color: transparent;
  }
}

// the .linkText classes can be applied
// to <a> links, or to spans that need
// to appear as a link
a.linkTextPrimary,
span.linkTextPrimary,
.btnTextPrimary {
  color: $green-100;
  background-color: transparent;

  transition: color $buttonTransition;

  &:hover,
  &:focus {
    color: $green-120;
    background-color: transparent;
  }
}

a.linkSecondary,
span.linkSecondary {
  color: $charcoal-90;
  background-color: transparent;

  transition: color $buttonTransition;

  &:hover,
  &:focus {
    color: $charcoal-100;
    background-color: transparent;
  }
}

.btnOutlined {
  background-color: transparent;
  border: $borderWidthSm solid currentColor;
  border-radius: 0.25rem;

  transition:
    color $buttonTransition,
    border-color $buttonTransition;

  svg {
    fill: currentColor;

    transition: fill $buttonTransition;
  }
}

.btnTextSecondary {
  color: $charcoal-90;
  background-color: transparent;

  transition: color $buttonTransition;

  &:hover,
  &:focus {
    color: $charcoal-100;
    background-color: transparent;
  }
}

// -----------------------------------------------------------------------------
// -- Sizes
// -----------------------------------------------------------------------------

.btnXs {
  line-height: 0.9;
  font-size: 0.8rem;
  padding: 0.375rem 0.65rem;
}

.btnSm {
  line-height: 1.1;
  font-size: 1rem;
  padding: 0.5625rem 1rem;
}

.btnMd {
  font-size: 1rem;
  padding: 0.875rem 1.5rem;
}

.btnLg {
  font-size: 1.25rem;
}

// -----------------------------------------------------------------------------
// -- Tempo
// -----------------------------------------------------------------------------

.tempo {
  .btn {
    font-family: $aktivGrotesk;
  }

  a.linkPrimary,
  .btnPrimary {
    &:hover,
    &:focus {
      color: $white-100;
      border-color: $tempo-navy-90;
      background-color: $tempo-navy-90;
    }

    &,
    &[disabled] {
      color: $white-100;
      border-color: $tempo-navy-100;
      background-color: $tempo-navy-100;
      text-decoration: none;
    }

    &[disabled] {
      opacity: 0.5;
    }
  }

  .btnPrimaryDark {
    &:hover,
    &:focus {
      color: $white-100;
      border-color: $tempo-navy-90;
      background-color: $tempo-navy-90;
    }

    &,
    &[disabled] {
      color: $white-100;
      border-color: $tempo-navy-100;
      background-color: $tempo-navy-100;
      text-decoration: none;
    }

    &[disabled] {
      opacity: 0.5;
    }
  }

  .btnOutlinePrimary {
    &:hover,
    &:focus {
      border-color: $tempo-blue-90;
      color: $charcoal-90;
    }

    & {
      border-color: $tempo-blue-90;
      color: $charcoal-90;
      background-color: $white-100;
    }

    &[disabled] {
      border-color: $charcoal-70;
      background-color: $charcoal-50;
    }
  }

  .btnOutlineSecondary {
    &:hover,
    &:focus {
      border-color: $tempo-black-100;
      color: $charcoal-90;
    }

    & {
      border-color: $tempo-black-100;
      color: $charcoal-90;
      background-color: $white-100;
    }

    &[disabled] {
      border-color: $charcoal-70;
      background-color: $charcoal-50;
    }
  }

  a.linkTextPrimary,
  span.linkTextPrimary,
  .btnTextPrimary {
    color: $tempo-blue-90;
    background-color: transparent;

    transition: color $buttonTransition;

    &:hover,
    &:focus {
      color: $tempo-navy-100;
      background-color: transparent;
    }
  }

  // Temporary to ensure proper contrast between link background and foreground
  a.linkTextHighContrast,
  button.btnTextHighContrast {
    color: $tempo-navy-90;
    background-color: transparent;

    transition: color $buttonTransition;

    &:hover,
    &:focus {
      color: darken($tempo-navy-90, 20%);
      background-color: transparent;
    }
  }

  .linkSecondary {
    color: $tempo-black-100;
    background-color: transparent;

    transition: color $buttonTransition;

    &:hover,
    &:focus {
      color: $charcoal-100;
      background-color: transparent;
    }
  }

  ::selection {
    @apply bg-tempo-blue-50;
  }
}

// -----------------------------------------------------------------------------
// -- One-off Buttons
// -----------------------------------------------------------------------------

// app/javascript/components/dashboard/Calendar.js
.btnCircle {
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 4em;
  max-height: 4em;

  padding: 0.75em;
  border: $borderWidthSm solid $charcoal-50;

  background-color: $ice-100;

  border-radius: $radiusCircle;
  cursor: pointer;

  transition: all 0.2s;

  &:hover {
    background-color: $charcoal-60;
    border-color: $charcoal-60;
  }

  &:focus {
    background-color: $charcoal-60;
  }

  &--homechef {
    &:focus {
      border-color: $green-100;
    }
  }

  &--tempo {
    &:focus {
      border-color: $tempo-blue-80;
    }
  }
}

.btnTempoYellow {
  background-color: $tempo-yellow-100;
  color: $tempo-black-100;

  &:hover,
  &:focus {
    background-color: darken($tempo-yellow-100, 10%);
    color: $tempo-black-100;
  }
}
@keyframes borderPulse {
  0% {
    box-shadow: none;
  }
  100% {
    box-shadow:
      0px 0px 19.5px var(--pulse-color),
      0px 0px 11px var(--pulse-color),
      0px 0px 6.5px var(--pulse-color),
      0px 0px 3px var(--pulse-color),
      0px 0px 1px var(--pulse-color),
      0px 0px 0.5px var(--pulse-color);
  }
}

.reviewOrderPulse {
  --pulse-color: #{$green-120};
  animation-name: borderPulse;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: 6;
  animation-delay: 20ms;
}

.tempo .reviewOrderPulse {
  --pulse-color: #{$tempo-navy-90};
}

.btnWhite {
  background-color: $white-100;
  color: $tempo-black-100;

  &:hover,
  &:focus {
    background-color: darken($white-100, 10%);
    color: $tempo-black-100;
  }
}

// Temporary to ensure proper contrast between footer background and foreground
a.linkTextHighContrast,
button.btnTextHighContrast {
  color: $green-120;
  background-color: transparent;

  transition: color $buttonTransition;

  &:hover,
  &:focus {
    color: $green-140;
    background-color: transparent;
  }
}

// -----------------------------------------------------------------------------
// -- Social
// -----------------------------------------------------------------------------

.button--email {
  color: $white-100;
  background-color: $charcoal-80;

  &:hover,
  &:focus {
    color: $white-100;
    background-color: $charcoal-80;
  }
}

.button--facebook {
  color: $white-100;
  background-color: $blue-facebook;

  &:hover,
  &:focus {
    color: $white-100;
    background-color: $blue-facebook;
  }
}

.button--pinterest {
  color: $white-100;
  background-color: $red-pinterest;

  &:hover,
  &:focus {
    color: $white-100;
    background-color: $red-pinterest;
  }
}

.button--twitter {
  color: $white-100;
  background-color: $blue-twitter;

  &:hover,
  &:focus {
    color: $white-100;
    background-color: $blue-twitter;
  }
}

.button--kroger {
  color: $white-100;
  background-color: $blue-kroger;

  &:hover,
  &:focus {
    color: $white-100;
    background-color: $blue-kroger;
  }
}

.button--google {
  color: $white-100;
  background-color: $blue-google;

  &:hover,
  &:focus {
    color: $white-100;
    background-color: $blue-google;
  }
}
