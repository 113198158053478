@use "variables" as *;

.background-fade:after {
  background-image: linear-gradient(to bottom, transparent 30%, $white-100 100%);
  position: absolute;
  height: 100%;
  width: 100%;
  content: '';
  bottom: 0;
}
