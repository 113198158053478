@use 'variables' as *;

.textInput,
.textareaInput {
  @apply inline-block w-full appearance-none py-4 transition-all;

  @apply rounded border border-solid border-charcoal-80;

  @apply appearance-none bg-white-100 font-karla text-base text-charcoal-100;

  &:focus {
    @apply outline-none ring ring-green-70;
  }

  &.tempo {
    @apply font-aktivGrotesk;

    &:focus {
      @apply outline-none ring ring-tempo-blue-70;
      @apply border border-solid border-tempo-blue-110;
    }
    &::selection {
      @apply bg-tempo-blue-50;
    }
  }
}

.textInput {
  height: 52px;
}

.textareaInput {
  @apply py-3;
}
